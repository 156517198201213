<template>
  <div>
    <div
      style="
        background: white;
        padding: 11px 0;
        border-radius: 5px 5px 0 0;
        border: 1px solid #e8e8e8;
      "
    >
      <el-button
        type="primary"
        size="medium"
        style="margin-left: 21px"
        @click="handleAddTabs"
        >新增标签</el-button
      >
    </div>
    <div class="table">
      <el-table :data="list" stripe height="800">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          label="标签名称"
          prop="tag_name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="添加人"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="添加时间"
          prop="create_time"
          align="center"
        ></el-table-column>
        <el-table-column
          label="最后修改时间"
          prop="update_time"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <div class="options">
              <el-select
                v-model="scope.row.select"
                placeholder="请选择"
                @change="handleDetails($event, scope.row)"
              >
                <el-option label="编辑" :value="1"></el-option>
                <el-option label="删除" :value="2"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="编辑标签"
      :visible.sync="editVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="cancelEdit"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label-width="120px" label="标签名称">
          <el-input
            placeholder="请输入标签名称"
            style="width: 200px"
            v-model="tagName"
            @keydown.native="keydown($event)"
          />
        </el-form-item>
        <div style="text-align: center">
          <el-button type="primary" @click="update()">保存</el-button>
          <el-button @click="cancelEdit">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="新增标签"
      :visible.sync="dialogVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="cancelAdd"
    >
      <div>
        <el-form>
          <template v-for="(item, index) in tag_name">
            <el-form-item
              :label="'标签名称' + (index + 1)"
              label-width="120px"
              :key="index"
            >
              <div style="display: flex">
                <el-input
                  placeholder="请输入标签名称"
                  style="width: 200px"
                  v-model="item.name"
                  maxlength="8"
                  show-word-limit
                  @keydown.native="keydown($event)"
                />
                <div style="margin: 7px 0 0 15px">
                  <img
                    src="../../../../assets/images/member/add.png"
                    @click="handleAdd(index)"
                  />
                </div>
                <div style="margin: 7px 0 0 15px">
                  <img
                    src="../../../../assets/images/member/del.png"
                    @click="handleDel(index)"
                  />
                </div>
              </div>
            </el-form-item>
          </template>
        </el-form>
        <div style="margin-left: 50px; margin-bottom: 10px">
          提示：标签名称1-8个字以内
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="submit('form')" :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelAdd">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTagList,
  createTab,
  deleteTab,
  updateTab,
} from "@/api/member/tabManage/index.js";
export default {
  data() {
    return {
      loading: false,
      list: [],
      dialogVisible: false,
      tag_name: [{ name: "" }],
      editVisible: false,
      tagName: "",
      e_client_tags_id: 0,
    };
  },
  created() {
    this.getTagList();
  },
  methods: {
    // 禁止输入空格
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
      }
    },
    cancelEdit() {
      this.editVisible = false;
      this.getTagList();
    },
    update() {},
    handleAddTabs() {
      this.dialogVisible = true;
      this.getTagList();
    },
    getTagList() {
      getTagList().then((res) => {
        if (res.code === 1) {
          this.list = res.data;
        }
      });
    },
    handleAdd() {
      this.tag_name.push({ name: "" });
    },
    handleDel(i) {
      this.tag_name = this.tag_name.filter((item, index) => index !== i);
    },
    cancelAdd() {
      this.dialogVisible = false;
      this.tag_name = [{ name: "" }];
    },
    submit() {
      const data = {
        is_start: 1,
        parent_id: 0,
      };
      data.tag_name = this.tag_name.map((item) => {
        return item.name;
      });
      createTab(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "新增成功！",
            type: "success",
          });
          this.cancelAdd();
          this.getTagList();
        }
      });
    },
    update() {
      const data = {
        is_start: 1,
        parent_id: 0,
        tag_name: this.tagName,
        e_client_tags_id: this.e_client_tags_id,
      };
      updateTab(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "更新成功！",
            type: "success",
          });
          this.cancelEdit();
          this.getTagList();
        }
      });
    },
    handleDetails(event, row) {
      this.e_client_tags_id = row.e_client_tags_id;
      if (event === 1) {
        this.editVisible = true;
        this.tagName = row.tag_name;
      }
      if (event === 2) {
        this.handleDelete(row.e_client_tags_id);
      }
    },
    /*
     *  删除分类
     */ handleDelete(id) {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTab({ e_client_tags_id: id }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getTagList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
